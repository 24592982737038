<template>
  <div id="reset-password">
    <v-card class="reset-password-card" elevation="2">
      <v-toolbar class="reset-password-card__toolbar" flat>
        <v-toolbar-title> {{ $t("Auth.ResetPassword") }} </v-toolbar-title>
      </v-toolbar>

      <v-divider />

      <v-card-text>
        <form v-if="!isShow" class="reset-password-form" @submit.prevent="reset">
          <v-text-field
            v-model="password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :error-messages="passwordErrors"
            :label="$t('Auth.NewPassword')"
            @blur="$v.password.$touch()"
            @click:append="showPassword = !showPassword"
            hide-details="auto"
          />

          <password-strength
            class="password-strength"
            v-model="password"
            :secureLength="PASSWORD_MAX_LENGTH"
            :strength-meter-only="true"
            @score="(score) => (this.passwordStrength = score)"
          />

          <v-text-field
            v-model="repeatPassword"
            prepend-icon="mdi-lock"
            :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showRepeatPassword ? 'text' : 'password'"
            :error-messages="repeatPasswordErrors"
            :label="$t('Auth.RepeatNewPassword')"
            required
            @blur="$v.repeatPassword.$touch()"
            @click:append="showRepeatPassword = !showRepeatPassword"
          />
          <v-btn type="submit" class="mt-4 mr-4" color="primary" :loading="isBusy" depressed block>
            {{ $t("Auth.ResetPassword") }}
          </v-btn>
        </form>

        <v-alert
          v-model="isShow"
          class="mt-5"
          :color="!errorCode ? 'success' : 'deep-orange'"
          :icon="!errorCode ? 'mdi-check' : 'mdi-fire'"
          text
          outlined
          :dismissible="!!errorCode"
        >
          {{ alertMessage }}
        </v-alert>

        <v-btn v-if="isShow" :to="{ name: 'login' }" class="mt-4" color="primary" block>
          {{ $t("Auth.SignIn") }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

import PasswordStrength from "vue-password-strength-meter";

import { PASSWORD_MAX_LENGTH } from "../../../services/validators.service";
import { API_URL } from "../../../config";

export default {
  name: "Reset",

  middleware: "guest",

  layout: "auth",

  mixins: [validationMixin],

  components: { PasswordStrength },

  validations: {
    password: {
      required,
      minLength: minLength(PASSWORD_MAX_LENGTH),
      strength: (_, vm) => vm.passwordStrength >= 2,
    },
    repeatPassword: { required, sameAsPassword: sameAs("password") },
  },

  metaInfo() {
    return { title: this.$t("Auth.ResetPassword") };
  },

  data: () => ({
    PASSWORD_MAX_LENGTH,

    password: "",
    repeatPassword: "",
    showPassword: false,
    showRepeatPassword: false,
    passwordStrength: 0,

    isShow: false,
    errorCode: null,

    isBusy: false,
  }),

  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("Validation.FieldRequired"));
      !this.$v.password.minLength && errors.push(this.$t("Validation.PasswordHelper"));
      !this.$v.password.strength && errors.push(this.$t("Validation.WeakPassword"));
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      !this.$v.repeatPassword.required && errors.push(this.$t("Validation.FieldRequired"));
      !this.$v.repeatPassword.sameAsPassword && errors.push("Passwords must be identical.");
      return errors;
    },

    alertMessage() {
      if (this.errorCode === "NO_SUCH_USER") return "No such user";
      return "Password updated successfully";
    },
  },

  methods: {
    async reset() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isBusy = true;
      this.errorCode = null;

      const token = this.$route.params.token;

      try {
        await axios.post(
          `${API_URL}/password/reset/${token}`,
          {
            password: this.password,
          },
          { config: { showNotify: false } },
        );
      } catch ({ response }) {
        const { data } = response;
        this.errorCode = data.code || null;
      } finally {
        this.isShow = true;
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-strength {
  max-width: 100%;
  margin-left: 33px;
}
</style>
